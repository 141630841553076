import GlossaryPage from '../containers/GlossaryPage';

export default GlossaryPage;

export const pageQuery = graphql`
         query GlossaryPageEnUsQuery {
           site {
             siteMetadata {
               languages {
                 langs
               }
             }
           }
           content: allContentfulTerm(
             sort: { order: ASC, fields: title }
             filter: { node_locale: { eq: "en-US" } }
           ) {
             ...GlossaryPageFragment
           }
         }
       `;
